/* #glCanvas {
    width: 512px;
    height: 512px;
} */

#info-section {
    position: absolute;
    bottom: 0;
    text-align: left;
    pointer-events: none;
    font-size: 14pt;
    margin: 0 0 16px 16px;
}

@media (max-width: 1280px) {
#info-section, #control-section {
    font-size: 10pt;
}
}

@media (min-width: 1280px) {
#info-section, #control-section {
    font-size: 10pt;
}
}

.canvas-holder {
    position: relative;
}

#control-section {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
    pointer-events: none;
    margin: 0 16px 16px 0;
}

#info-section > span, #info-section label, #control-section label {
    background-color: #3f3c3c44;
    color: #ffffff;
    user-select: none;
    pointer-events: none;
}

#color-picker {
    width: 200px;
    height: 60px;
    pointer-events: auto;
}

#speed-range {
    pointer-events: auto;
}

