.resume-box>h2 {
    font-size: x-large;
    font-weight: 500;
}

.resume-box ul {
    list-style-type: circle;
    padding-left: 30px;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.resume-flag {
    display: inline;
    height: 24px;
    margin-right: 6px;
}

.resume-avatar>img {
    display: block;
    height: 150px;
    border-radius: 75px;
    border: 1px solid rgb(219, 219, 219);
    margin: 0 auto;
}